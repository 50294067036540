import React from 'react';

import PageHome from './PageHome'
import JewelryGroup from './JewelryGroup'
import PageRealEstateGroup from './PageRealEstateGroup'
import PageRealEstateDetail from './PageRealEstateDetail'

import { BrowserRouter, Route, Switch  } from "react-router-dom";

import store from '../redux/storeConfig'
import { Provider } from 'react-redux'


class App extends React.Component {



  render(){

    const HsbcBuilding = (props) => <PageRealEstateDetail {...props} pid='hsbc-building' />
    const HotelDeVendome = (props) => <PageRealEstateDetail {...props} pid='hotel-de-vendome' />
    const villaRosePierre = (props) => <PageRealEstateDetail {...props} pid='villa-rose-pierre' />
    const grandHotel = (props) => <PageRealEstateDetail {...props} pid='grand-hotel-du-cap-ferret' />
    const ReefIsland = (props) => <PageRealEstateDetail {...props} pid='reef-island' />
    const GrandHill = (props) => <PageRealEstateDetail {...props} pid='grand-hill' />
    const PrivateMuseum = (props) => <PageRealEstateDetail {...props} pid='private-museum' />
  

    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={(props) => <PageHome {...props} />} />
            <Route exact path="/jewelry-group" render={(props) => <JewelryGroup {...props} />} />
            <Route exact path="/real-estate-group" render={(props) => <PageRealEstateGroup {...props} />}/>

            <Route path="/hsbc-building*" component={HsbcBuilding} />
            <Route path='/hotel-de-vendome*' component={HotelDeVendome} />
            <Route path="/villa-rose-pierre*" component={villaRosePierre} />
            <Route path="/grand-hotel-du-cap-ferret*" component={grandHotel} />
            <Route path="/reef-island*" component={ReefIsland} />
            <Route path="/grand-hill*" component={GrandHill} />
            <Route path="/private-museum*" component={PrivateMuseum} />

            <Route path="/*" render={(props) => <PageHome {...props} />} />

          </Switch>

        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;