const jsonResponse =[
      {
        "link":'reef-island',
        "filtergroup": "resort",
        "title": "Reef Island",
        "status" : "",
        "subtitle": "An island of bliss in the heart of the city / Manama, Bahrain",
        "description": "<p>On the northern shore of Manama, minutes away from the new Bahrain Financial Harbour, Reef Island is set to redefine luxury living in the city. With its convenient location, this purpose-built atoll combines the best of both worlds, offering its residents and guests easy access to Bahrain’s capital city while providing them with the tranquillity of a tropical island getaway.</p><p>Residents can select from over 1,200 apartments in 39 different buildings, offering 12 unique yet harmonized designs. The selection of accommodation includes two-, three- and four-bedroom apartments, as well as penthouses, ranging from 133m² to 596m². Yet more choice awaits in the north of the island where 49 beach villas (ranging from 190m² to 355m²) and 65 individually styled ultra-luxurious villas (from 525 m² to 1200 m²) come complete with sea or lagoon views.</p><p>A first-class hotel with 250 spacious and luxuriously appointed rooms caters to island guests. In addition to the hotel’s prestigious facilities, Reef Island’s wealth of amenities will ensure life on the island is unforgettable. Whether indulging in internationally renowned spa treatments at the state-of-the-art Spa Village, soaking up the atmosphere at the exclusive Marina and Yacht Club or on the pristine beach, those that visit Reef Island will find a world of idyllic bliss.</p><p>Every detail has been thought out to make Reef Island the ultimate living experience. Health needs are fully covered by the resort’s Well Care Centre, which offers the highest level of medical expertise for in- and out-patient care. The island’s 30,000m² retail mall, filled with upscale brands and diverse dining experiences, ensures an infinite diversity of leisure options. With an abundance of greenery, parks and a seaside pedestrian promenade, Reef Island is the ideal location to life to the full.</p>",
        "image":"images/real-estate/thumbs/reef-island.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
          {caption:"",src:"6.jpg"},
          {caption:"",src:"7.jpg"},
          {caption:"",src:"8.jpg"},
          {caption:"",src:"9.jpg"},
          {caption:"",src:"10.jpg"},
          {caption:"",src:"11.jpg"}
        ]
      },
      {
        "link":'hsbc-building',
        "filtergroup": "building",
        "title": "HSBC Building ",
        "status" : "*This property is no longer in the portfolio.",
        "subtitle": "Swiss sophistication / Geneva, Switzerland",
        "description": "<p>In the center of Switzerland’s sophisticated metropolis of Geneva, overlooking the city’s lake, Immeuble 37, Quai Wilson offers a suitably prestigious address for the global financial institution HSBC. With its striking classic architecture and surrounding luxurious locale, Immeuble 37 emanates an impressive allure, enhanced by its view of the Swiss mountains and the lake’s famous Jet d’eau.</p><p>The five-floor building has an additional attic as well as three basements, providing generous office and storage space. The building also offers two terraces and a courtyard with total built-up space exceeding 8,000m². Convenient access from two different entrances adds yet another plus to these commercial premises with stunning appeal.</p>",
        "image":"images/real-estate/thumbs/hsbc-building.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
        ]
      },
      {
        "link":'grand-hill',
        "filtergroup": "hotel",
        "title": "Grand Hills",
        "status" : "",
        "subtitle": "Grand in every sense of the word / Brummana, Lebanon",
        "description": "<p>Situated on a vast 35,000m² of land in the mountain village of Brummana, Grand Hills Hotel & Spa is designed as a picturesque pedestrian pinewood village where a world of luxurious hospitality awaits. At 750 meters above sea level, rising majestically from its surroundings, the resort capitalizes on the area’s renowned greenery, enviable climate and sweeping coastal and mountain views.</p><p>The resort’s five-star hotel features 118 hotel rooms and 52 luxurious furnished residential apartments. Its Royal Residence measures an impressive 4,131m², winning it acclaim from the Guinness Book of Records as the largest hotel suite in the world. Full business and leisure facilities include restaurants, an international spa, three swimming pools, banquet and conference facilities and a shopping centre.</p><p>The ambiance throughout the resort is one of Mediterranean charm, with fountains, streams and waterfalls adding an aquatic accent to the paved terraces, walkways and lush vegetation. The impressive size and facilities of the Grand Hills Hotel & Spa are complemented by an unrivaled level of personalized service, which together make it Lebanon’s most prestigious resort.</p>",
        "image":"images/real-estate/thumbs/grand-hill.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
          {caption:"",src:"6.jpg"},
          {caption:"",src:"7.jpg"},
          {caption:"",src:"8.jpg"},
          {caption:"",src:"9.jpg"},
          {caption:"",src:"10.jpg"},
          {caption:"",src:"11.jpg"}
        ]
      },
      {
        "link":'villa-rose-pierre',
        "filtergroup": "residential",
        "title": "Villa Rose-Pierre",
        "status" : "*This property is no longer in the portfolio.",
        "subtitle": "Shades of sophistication  / Côte d’Azur, France",
        "description": "<p>Villa Rose-Pierre is a luxury residence where the timeless beauty of the French Riviera can be enjoyed in idyllic style. Conveniently located 20 minutes away from Nice Cote d’Azur International Airport, the villa is set in 17 acres of pine-studded forest and perfectly manicured gardens.</p><p>Villa Rose-Pierre takes its name from its salmon pink walls, which blend with the Riviera sun to cast an inviting warmth throughout the residence. The interior design combines a modern Mediterranean aesthetic with traditional European sophistication. The result is a harmonious ode to luxury which enchants with its aesthetic intrigue. Tiles and mosaics throughout the villa reflect the Mediterranean theme, while a spiral staircase adds a dramatic touch. Eclecticism pervades the villa, such as in the opulent dining room with its cornucopia of ethnic style. The four color-themed bedrooms each include their own luxurious lounge and office areas while offering picturesque sea views. The bathrooms continue the luxury theme with Jacuzzis.</p><p>In the villa grounds the rich textures and sweeping curves of the pool beckon for a refreshing dip. A tennis court, sauna and gym room complete the leisure facilities. For pure relaxation, nothing beats a stroll through the gardens, where the refreshing sea air and pine scented forests conjure an unforgettable ambiance.</p>",
        "image":"images/real-estate/thumbs/villa-rose-pierre.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
          {caption:"",src:"6.jpg"},
          {caption:"",src:"7.jpg"},
          {caption:"",src:"8.jpg"}
        ]
      },
      {
        "link":'grand-hotel-du-cap-ferret',
        "filtergroup": "hotel",
        "title": "Grand-Hotel du Cap-Ferrat",
        "status" : "*This property is no longer in the portfolio.",
        "subtitle": "Sensational seclusion / Côte d’Azur, France",
        "description": "<p>For nearly a century, the Grand-Hotel du Cap-Ferrat has enchanted distinguished guests with its stately luxury and commanding views of the French Riviera. Its setting in a private park on the southernmost tip of the Cap-Ferrat peninsula gives it an exclusivity that, combined with the panoramic views and superior service, makes this one of the Riviera’s most idyllic havens.</p><p>Entered through the hotel’s lavish gardens, the reception area conveys a regal sophistication softened by the conviviality of fresh flowers and a gracious welcome. Across the hotel’s rooms and suites, the concepts of comfort, relaxation, refinement and luxury are blended to stunning effect. Each room is decorated with its own sense of unique style with the utmost attention given to the finest furnishings, fabrics and materials.</p><p>Gastronomic masterpieces await guests at the hotel’s Le Cap restaurant, which offers the choice of seating amid the splendour of its interior restaurant or on the patio where panoramic sea views add to the sensory experience. For fine dining in a casual outdoor setting, guests can be seated poolside and savor the delights of Club Dauphin. Although the shimmering waves of the Mediterranean are within view, the Grand-Hotel du Cap-Ferrat’s pools provide a tempting alternative for guests who would rather stay within the hotel. Those that wish to venture further can take a funicular to the seashore or pier, and even reserve a private beach tent for a heavenly massage beside the sea.</p>",
        "image":"images/real-estate/thumbs/grand-hotel-du-cap-ferret.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
        ]
      },
      {
        "link":'hotel-de-vendome',
        "filtergroup": "hotel",
        "title": "Hotel De Vendôme",
        "status" : "*This property is no longer in the portfolio.",
        "subtitle": "Regal revival / Paris, France",
        "description": "<p>Three centuries of art, culture and elegance interlace to create a unique experience at the Hotel de Vendôme. Set in the heart of Paris, in the famous Place Vendôme, the hotel is surrounded by the cream of haute couture, antique dealers, art galleries and landmarks such as the Opera and Louvre Museum. The building was constructed in the 19<sup>th</sup> century by Louis XIV’s secretary and, throughout the following years, underwent a metamorphosis as a private residence, embassy, administrative headquarters and, finally, hotel. In 1998 it was reopened after four years of extensive renovations which achieved the revival of its original grandeur.</p><p>The hotel’s 19 rooms and 10 suites have been designed to offer state-of-the-art technology with the ultimate in comfort and luxury. Each room boasts a unique decor with the focus on beauty, space and the finest craftsmanship. Throughout the hotel the classic French design with gold engraved sculpting, marble inlaid flooring, chandeliers and objets d’art conveys a palatial splendor in keeping with the hotel’s regal history.</p>",
        "image":"images/real-estate/thumbs/hotel-de-vendome.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
        ]
      }
      ,
      {
        "link":'private-museum',
        "filtergroup": "museum",
        "title": "Robert Mouawad Private Museum",
        "status" : "",
        "subtitle": "History in the making / Beirut Central District, Lebanon",
        "description": "<p>Much in the same spirit as its exclusive neighborhood of the Beirut Central District, the Robert Mouawad Private Museum pays tribute to the cultural riches of the past with a reinvigorated flair. This palatial private residence, built in 1892, was acquired by the Robert Mouawad Group and transformed into a private museum to preserve the legacy of the region’s antiquities and that of Mouawad’s exquisite jewelry.</p><p>In renovating the structure, the Robert Mouawad Group has remained true to the building’s heritage and traditions. Secluded from the outside world, the walled museum is set in idyllic grounds which combine exotic greenery with statues and columns from bygone eras.</p><p>The restored early 20<sup>th</sup> century facade of the museum gives off an immediate romanticism and charm of yesteryear. Once inside, discreet state-of-the-art technology brings the artefacts to life, creating a dazzling showcase of rare facets of history. On display are a wealth of books, ceramics, statues, pottery, ancient weapons, carpets, luxury jewelry, objets d’art and rare precious stones, each weaving their own historical tale in this magical setting.</p>",
        "image":"images/real-estate/thumbs/private-museum.jpg",
        "images": [
          {caption:"",src:"1.jpg"},
          {caption:"",src:"2.jpg"},
          {caption:"",src:"3.jpg"},
          {caption:"",src:"4.jpg"},
          {caption:"",src:"5.jpg"},
        ],
        "imagesMore": [
          {caption:"",src:"6.jpg"},
          {caption:"",src:"7.jpg"},
          {caption:"",src:"8.jpg"}
        ]
      }


]
  
export default jsonResponse;