import React from 'react';
import {HashLink as Link} from 'react-router-hash-link'

import { connect } from 'react-redux'
import { setTitle } from '../redux/actions/dynamicTitleAction'

import DataSource from '../data/fakeData'

class Header extends React.Component{

    portfolioMenuList = DataSource
        .filter((object) => object.link !== null)
        .map((object,i) => <li key={i}><Link to={object.link}>{object.title}</Link></li>)

    render() {

        let navLinkInfo = this.props.location.pathname + this.props.location.hash
        if (navLinkInfo === '/'){
            navLinkInfo = '/#home'
        }
        
        return(
            <header>
                <div className="header-inner">
                    <div className="logo-holder">
                      <Link to="/#home" className={navLinkInfo === '/#home' ? 'act-link' : ''} onClick={this.props.setTitleHome}><img src="images/robert-mouawad.png" alt="Home" /></Link>
                    </div>
                    <div className="nav-button-holder">
                        <div className="nav-button vis-m"><span></span><span></span><span></span></div>
                    </div>
                    <div className="nav-holder">
                        <nav>
                        <ul>
                            {/* <li><Link to="/#home" className={navLinkInfo === '/#home' ? 'act-link' : ''} onClick={this.props.setTitleHome}>Home</Link></li> */}
                            <li><Link to="/#home" className={navLinkInfo === '/#home' ? 'act-link' : ''} onClick={this.props.setTitleAbout}>About</Link></li>
                            <li><Link to="/jewelry-group" className={navLinkInfo === '/jewelry-group' ? 'act-link' : ''}>Jewelry Group</Link></li>
                            <li><Link to="/real-estate-group" className={navLinkInfo === '/real-estate-group' ? 'act-link' : ''}>REAL ESTATE GROUP</Link>                       <ul>
                                    { this.portfolioMenuList }
                                </ul>
                            </li>
                        </ul>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}


const mapStageToProps = (state) => ({
    dynamicTitleReducer: state.dynamicTitleReducer
})
    
const mapDispatchToProps = (dispatch) =>({
    setTitleHome: () => dispatch(setTitle('Home')),
    setTitleAbout: () => dispatch(setTitle('About')),
})

export default connect(mapStageToProps,mapDispatchToProps)(Header)