import React from 'react';
import {HashLink as Link} from 'react-router-hash-link'

import { connect } from 'react-redux'


class Footer extends React.Component{
    scrollToTop = () =>{
        window.$("html, body").animate({
            scrollTop: 0
        }, 500);
    }
    render(){
        return(
            <React.Fragment>
                <footer className="fixed-footer">
                    <div className="footer-title">
                        <h2><Link to={this.props.location.pathname + this.props.location.hash} >{this.props.dynamicTitleReducer.dynamicTitle}</Link></h2>
                    </div>
                </footer>
        
        
                {/* <!-- content footer--> */}
                <div className="height-emulator"></div>
                <footer className="content-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-xs-10">
                            <div className="footer-wrap">
                                <span className="copyright">COPYRIGHT © 2020 ROBERT MOUAWAD. ALL RIGHTS RESERVED.</span>
                            </div>
                            </div>
                            <div className="col-md-2 col-xs-2">
                                <div className="footer-wrap">
                                    <span className="to-top" onClick={this.scrollToTop}>To Top</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        
                <div className="share-inner">
                    <div className="share-container  isShare" data-share="['facebook','googleplus','twitter','linkedin']"></div>
                    <div className="close-share"></div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStageToProps = (state) => ({
    dynamicTitleReducer: state.dynamicTitleReducer
})
    
export default connect(mapStageToProps)(Footer)