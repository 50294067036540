import React from 'react';
import Header from './Header'
import Footer from './Footer'
import {HashLink as Link} from 'react-router-hash-link'

import { connect } from 'react-redux'
import { setTitle } from '../redux/actions/dynamicTitleAction'

import DataSource from '../data/fakeData'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class PageRealEstateDetail extends React.Component {  
    
    constructor(props){
        super(props)

        const pageContent = DataSource.filter(obj => obj.link === this.props.pid)
        .reduce( (sum,obj) => obj, {})

        const showMore = (pageContent.imagesMore.length === 0)
    
        this.state = {
            showMore,
            pageContent 
        }

        this.handleShowMore = this.handleShowMore.bind(this);
        this.props.setPageTitle(this.state.pageContent.title)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        window.initmobileHover()
        window.initMonolit()
        window.initparallax()  
    }

    Portfolio_images =  () => this.state.pageContent.images.map((object,key) => {
        return(
            <div key={key} className="gallery-item">
                <div className="grid-item-holder">
                    <div className="box-item">
                        <a href={`images/real-estate/${this.state.pageContent.link}/${object.src}`} className="popup-image" data-sub-html={object.content}>
                            <span className="overlay"></span>
                            <LazyLoadImage src={`images/real-estate/${this.state.pageContent.link}/${object.src}`} alt={object.content} afterLoad={()=>{window.initMonolit();window.initparallax();}} />
                        </a>
                    </div>
                </div>
            </div>
        )
    })

    Portfolio_images_more =  () => this.state.pageContent.imagesMore.map((object,key) => {
        // console.log(object.src)
        return(
            <div key={`more_${key}`} className={`gallery-item ${this.state.showMore ? "show" : "hide"}`}>
              <div className="grid-item-holder">
                <div className="box-item">
                    <a href={`images/real-estate/${this.state.pageContent.link}/${object.src}`} className="popup-image" data-sub-html={object.content}>
                      <span className="overlay"></span>
                      <LazyLoadImage src={`images/real-estate/${this.state.pageContent.link}/${object.src}`} alt={object.content} afterLoad={()=>{window.initMonolit();window.initparallax();}} />
                    </a>
                </div>
              </div>
            </div>
        )
    })

    handleShowMore(e) {
        e.preventDefault();
        this.setState({
            ...this.state,
            showMore: true
        });
        setTimeout(() => {        
            window.initMonolit()
            window.initparallax()  
        }, 700); 
    }

    render() {

        const pageAll = DataSource.filter(obj => obj.link !== null)
        const pageIndex = pageAll.indexOf(this.state.pageContent);

        let prevLink = null
        let nextLink = null

        if (pageIndex !== 0) {
            prevLink = pageAll[pageIndex - 1].link
        } else {
            prevLink = pageAll[pageAll.length-1].link
        }
        if (pageIndex !== pageAll.length -1) {
            nextLink = pageAll[pageIndex + 1].link
        } else {
            nextLink = pageAll[0].link
        }

        return(

            <div id="wrapper">
                <div className="content-holder  real-esate-detail">
    
                    <Header {...this.props}/>

                    <div className="dynamic-title">{this.props.dynamicTitleReducer.dynamicTitle}</div>
                    <div className="content">
                        <section className="parallax-section">
                            <div className="parallax-inner">
                                <div className="bg" data-bg="images/bg/real-estate-head.jpg" data-top-bottom="transform: translateY(300px);"
                                data-bottom-top="transform: translateY(-300px);"></div>
                                {/* <!-- <div className="overlay"></div> --> */}
                            </div>
                            <div className="container">
                                <div className="page-title">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2>
                                              {this.state.pageContent.title}
                                            </h2>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="creat-list">
                                                <li><Link to="/#home">Home</Link></li>
                                                <li><Link to="/real-estate-group">Real Estate Group</Link></li>
                                                <li><Link to={this.props.location.pathname} >{this.state.pageContent.title}</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="content">
                        <section>
                            <div className="container">
                                {/* <!--=============== portfolio holder ===============--> */}
                                <div className="gallery-items three-columns grid-small-pad lightgallery">
                                    {this.Portfolio_images()}
                                    {this.Portfolio_images_more()}                                    
                                </div>
                                
                                <div className={`btn-view-more ${this.state.showMore ? "hide" : "show"}`}>
                                    <button className="btn anim-button fl-l" onClick={this.handleShowMore} ><span>VIEW MORE PHOTOS</span><i className="fa fa-long-arrow-right"></i></button>
                                </div>

                                {/* <!-- end gallery items --> */}
                                <div className="custom-inner-holder">
                                    {/* <!-- 1 --> */}
                                    <div className="custom-inner">
                                        <div className="row">
                                            <div className="col-md-2 col-md-offset-1 col-xs-12">
                                                <h3 className="desktop-detail">details</h3>
                                                <h3 className="mobile-header">{this.state.pageContent.title}</h3>
                                            </div>
                                            <div className="col-md-9 col-xs-12">
                                                <h4>{this.state.pageContent.subtitle}</h4>
                                                <p dangerouslySetInnerHTML={{__html: this.state.pageContent.description}} />
                                                <span>{this.state.pageContent.status}</span>
                                                <div className="content-nav">
                                                    <ul>
                                                        <li><Link to={nextLink} className=" text-link">next project</Link></li>
                                                        <li><Link to={prevLink} className=" text-link">prev project</Link></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- 1 --> */}
                                </div>
                                {/* <!-- custom-link-holder  end --> */}
                            </div>
                        </section>
                    </div>

                    <Footer {...this.props} />

                </div>
            </div>
        )
    }
}

const mapStageToProps = (state) => ({
    dynamicTitleReducer: state.dynamicTitleReducer
})
    
const mapDispatchToProps = (dispatch) =>({
    setPageTitle: (title) => dispatch(setTitle(title))
})

export default connect(mapStageToProps,mapDispatchToProps)(PageRealEstateDetail)