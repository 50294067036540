import React from 'react';
import Header from './Header'
// import Footer from './Footer'
import {HashLink as Link} from 'react-router-hash-link'

import { connect } from 'react-redux'
import { setTitle } from '../redux/actions/dynamicTitleAction'

import DataSource from '../data/fakeData'
import { LazyLoadImage } from 'react-lazy-load-image-component';


class PageRealEstateGroup extends React.Component {  

    constructor(props){
        super(props)
        this.props.setPageTitle('REAL ESTATE GROUP')
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        window.initmobileHover()
        window.initMonolit()    
        window.initparallax()
        window.initFilter()      
    }

    portfolioItems = DataSource.map((object,i) => ( 
        <div key={i} className={`portfolio_item ${object.filtergroup}`}>
            <LazyLoadImage src={object.image} alt={object.title} afterLoad={()=>{window.initMonolit();window.initparallax();}} />
            <Link to={object.link} className="port-desc-holder">
                <div className="port-desc">
                    <div className="grid-item">
                        <h3>{ object.title }</h3>
                        <span>{object.subtitle}</span>
                    </div>
                </div>
            </Link>
        </div>
    ))

    render() {
        return(
            <div id="wrapper">
                <div className="content-holder">
    
                    <Header {...this.props} />

                    <div className="dynamic-title">{this.props.dynamicTitleReducer.dynamicTitle}</div>

                    <div className="content hor-content full-height pad-con no-bg-con">
                        <div className="filter-holder round-filter">
                            <div className="gallery-filters hid-filter">
                                <a href="/" className="gallery-filter transition2 gallery-filter_active" data-filter="*">All Project</a>
                                <a href="/" className="gallery-filter transition2" data-filter=".hotel">Hotels</a>
                                <a href="/" className="gallery-filter transition2" data-filter=".resort">Resort</a>
                                <a href="/" className="gallery-filter transition2" data-filter=".residential">Residents</a>
                                {/* <a href="/" className="gallery-filter transition2" data-filter=".museum">Museum</a> */}
                                <a href="/" className="gallery-filter transition2" data-filter=".building">Building</a>
                            </div>
                            <div className="clearfix"></div>
                            <div className="filter-button">Filter</div>
                            <div className="count-folio round-counter">
                                <div className="num-album"></div>
                                <div className="all-album"></div>
                            </div>
                        </div>

                        <div className="resize-carousel-holder hpw">
                            <div className="p_horizontal_wrap">
                                <div id="portfolio_horizontal_container" className="two-ver-columns">

                                    {this.portfolioItems}
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="fixed-footer">
                        <div className="footer-title">
                            <h2><Link to={this.props.location.pathname + this.props.location.hash} >{this.props.dynamicTitleReducer.dynamicTitle}</Link></h2>
                        </div>
                    </footer>

                </div>
            </div>
        )
    }
}

const mapStageToProps = (state) => ({
    dynamicTitleReducer: state.dynamicTitleReducer
})
    
const mapDispatchToProps = (dispatch) =>({
    setPageTitle: (title) => dispatch(setTitle(title))
})

export default connect(mapStageToProps,mapDispatchToProps)(PageRealEstateGroup)