import React from 'react';
import Header from './Header'
import Footer from './Footer'
// import {HashLink as Link} from 'react-router-hash-link'

import { connect } from 'react-redux'
import { setTitle } from '../redux/actions/dynamicTitleAction'


class PageHome extends React.Component {  

    constructor(props){
        super(props)
        const navLinkInfo = this.props.location.pathname + this.props.location.hash
        switch(navLinkInfo){
            case '/#about':
                this.props.setPageTitle('About')
                break;

            default:
                this.props.setPageTitle('Home')
        }  
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        window.initmobileHover()
        window.initMonolit()
        window.initparallax()  
    }

    render() {
        return(
            <div id="wrapper">
                <div className="content-holder">
    
                    <Header {...this.props} />

                    <div className="dynamic-title">{this.props.dynamicTitleReducer.dynamicTitle}</div>

                    <div id="home" className="content">
                        <div className="hero-wrap">
                            {/* <!-- Hero image   --> */}
                            <div className="bg desktop" data-bg="images/robert-mouawad-main.jpg"><img src="images/robert-mouawad-main.jpg" alt="" /></div>
                            <div className="bg tablet" data-bg="images/robert-mouawad-main-tablet.jpg"><img src="images/robert-mouawad-main-tablet.jpg" alt="" /></div>
                            <div className="bg mobile" data-bg="images/robert-mouawad-main-mobile.jpg"><img src="images/robert-mouawad-main-mobile.jpg" alt="" /></div>
                            {/* <!-- Hero image   end --> */}
                            <div className="overlay"></div>
                            {/* <!-- Hero text   --> */}
                            <div className="hero-wrap-item">
                                <h2><span>A VISIONARY APPROACH</span> TO BUSINESS</h2>
                            </div>
                            {/* <!-- Hero text   end--> */}
                            <a href="#sec1" className="hero-scroll-link custom-scroll-link" data-top-bottom="transform: translateY(50px);"
                            data-bottom-top="transform: translateY(-50px);" onClick={this.props.setTitleAbout}><i className="fa fa-angle-down"></i></a>
                        </div>
                    </div>

                    <div id="about" className="content">
                        <section id="sec1">
                            {/* <!--  container  --> */}
                            <div className="container about">
                                <div className="row">
                                    {/* <div className="col-md-4"> */}
                                        {/* <!-- single slider  --> */}
                                        {/* <div className="parallax-box slider-box ">
                                            <div className="single-slider-holder">
                                                <div className="single-slider"> */}
                                                    {/* <!-- 1 --> */}
                                                    {/* <div className="item">
                                                        <img src="images/about-robert-mouawad.png" alt="" />
                                                    </div> */}
                                                    {/* <!-- 1 end--> */}
                                                {/* </div>
                                            </div> */}
                                            {/* <!-- single slider  end --> */}
                                        {/* </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        {/* <!-- section title  --> */}
                                        <h2 className="section-title">ABOUT</h2>
                                        {/* <!-- section title  end --> */}
                                        <img src="images/about-subtitle.png" className="img-responsive desktop" alt="AN EXPERT EYE FOR THE EXQUISITE" />
                                        <img src="images/about-subtitle-mobile.png" className="img-responsive mobile" alt="AN EXPERT EYE FOR THE EXQUISITE" />
                                        <p>Combining creative energy with commitment and vision, Robert Mouawad transformed his family business into a global empire of renown. It was the early 1970s when Robert Mouawad built on his family’s heritage as luxury timepiece producers to emerge as a key player in the world of fine jewelers. From Saudi Arabia, Robert looked to new horizons, expanding the business in the early 1970s far beyond the original jewelry salon in Jeddah, Saudi Arabia and selecting Geneva as the company’s new base. Robert built the Mouawad brand in myriad ways. He broadened his knowledge through exhaustive research into art, craftsmanship, and state-of-the-art technology. His tenure as CEO was characterized by company growth, expansion, and the family’s permanent quest for perfection. Under his leadership, the Mouawad brand established a presence in Europe, Asia, and North America. Through his dynamism and commitment to the jewelry industry, Robert Mouawad led the family business to international acclaim. </p>
                                        <p>Just as Robert’s geographical ambitions took the business to new heights, so did his love of precious gems. In this respect, he was a visionary, taking risks at times when others were afraid to buy. His presence was instantly felt in any auction room, as he went on to acquire some of the world’s largest diamonds at fantastic prices. In an industry that places the highest emphasis on trust, Robert Mouawad was able to earn respect for his word and secured his place in the history books as one of the diamond industry’s biggest players. Over the years he has collected some of the world’s most important diamonds and designed some of the most stunning jewelry masterpieces in existence. Today the name Mouawad represents the pinnacle of its field.</p>
                                        <p>Driving this phenomenal success is Robert Mouawad’s creative talent. At heart, he is an artist. And, like all great artists he is driven by an innate passion to create and to build and in doing so to share this passion with the world. His expert eye for the exquisite is not only exemplified by the dazzling jewelry creations of Mouawad but by the premier real estate projects that carry the Robert Mouawad Group name. The company’s portfolio contains many of the world’s finest hotels, either sought out and acquired or conceived and developed by the Robert Mouawad Group. Across the portfolio of company properties, there is one unifying theme: Robert Mouawad’s visionary approach to beauty and to luxury. </p>
                                        <p><a href="https://www.mouawad.com" target="_blank" rel="noopener noreferrer">www.mouawad.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* <!--  container end  --> */}
                        </section>
                    </div>                        


                    {/* <div className="content section-link">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="image-container" >
                            <img src="images/archivements.jpg" className="img-responsive desktop" alt="" />
                            <img src="images/archivements-mobile.jpg" className="img-responsive mobile" alt="" />
                          </div>
                          <div className="content-container box-right" >
                            <h3 className="section-title"><span> Jewelry Group</span></h3>
                            <Link to="/achievements" rel="noopener noreferrer" className="btn anim-button fl-l"><span>DISCOVER MORE</span><i className="fa fa-long-arrow-right"></i></Link>
                          </div>
                        </div>
                      </div>
                    </div> */}


                    
                    {/* <div className="content section-link">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="image-container" >
                            <img src="images/real-estate-group.jpg" className="img-responsive desktop" alt="" />
                            <img src="images/real-estate-group-mobile.jpg" className="img-responsive mobile" alt="" />
                          </div>
                          <div className="content-container box-left" >
                            <h3 className="section-title"><span>REAL ESTATE</span> GROUP</h3>
                            <Link to="/real-estate-group" rel="noopener noreferrer" className="btn anim-button fl-l"><span>DISCOVER MORE</span><i className="fa fa-long-arrow-right"></i></Link>
                          </div>
                        </div>
                      </div>
                    </div> */}


                    <Footer {...this.props} />

                </div>
            </div>
        )
    }

}

const mapStageToProps = (state) => ({
    dynamicTitleReducer: state.dynamicTitleReducer
})
    
const mapDispatchToProps = (dispatch) =>({
    setPageTitle: (title) => dispatch(setTitle(title))
})

export default connect(mapStageToProps,mapDispatchToProps)(PageHome)