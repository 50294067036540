export default function dynamicTitleReducer(state = {dynamicTitle:""}, action){
    switch (action.type) {
        case 'SET_TITLE':
            return  {
                dynamicTitle:action.title
            }

        default:
            return state
    }
}
