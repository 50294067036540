import React from 'react';
import Header from './Header'
import Footer from './Footer'
import {HashLink as Link} from 'react-router-hash-link'

import { connect } from 'react-redux'
import { setTitle } from '../redux/actions/dynamicTitleAction'
import { LazyLoadImage } from 'react-lazy-load-image-component';

class PageHome extends React.Component {  

    constructor(props){
        super(props)
        this.props.setPageTitle('Jewelry Group')
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        window.initmobileHover()
        window.initMonolit()
        window.initparallax()        
    }

    render() {
        return(
            <div id="wrapper">
                <div className="content-holder real-esate-detail archivement">

                    <Header {...this.props}/>

                    <div className="dynamic-title">{this.props.dynamicTitleReducer.dynamicTitle}</div>

                    <div className="content">
                        <section className="parallax-section">
                            <div className="parallax-inner">
                                <div className="bg" data-bg="images/bg/real-estate-head.jpg" data-top-bottom="transform: translateY(300px);"
                                data-bottom-top="transform: translateY(-300px);"></div>
                            </div>
                            <div className="container">
                                <div className="page-title">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2> Jewelry Group </h2>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="creat-list">
                                                <li><Link to="/#home">Home</Link></li>
                                                <li><Link to="/jewelry-group"> Jewelry Group </Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="content">
                      <section>
                        <div className="container">
                          <div className="custom-inner-holder">
                              <div className="custom-inner">
                              <div className="row">
                                  <div className="col-xs-12">
                                    <div className="image-container">
                                      <LazyLoadImage src="images/mouawad-gia.jpg" className="img-responsive" alt="GIA Robert Mouawad Campus" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-md-offset-1 col-xs-12">
                                        <h3 className="desktop-detail">GIA Robert Mouawad Campus</h3>
                                        <h3 className="mobile-header">GIA Robert Mouawad Campus</h3>
                                    </div>
                                    <div className="col-md-7 col-xs-12">
                                        <p>The Mouawad family’s association with the gem and jewelry industry goes beyond creating fine jewelry to supporting the industry as a whole. This support has been demonstrated through diverse initiatives and was formally recognized by the Gemological Institute of America (GIA). </p>
                                        <p>Established in 1931, the GIA is an independent nonprofit organization recognized as the world’s foremost authority in gemology. Through research, education, gemological laboratory services, and instrument development, the Institute is dedicated to ensuring the public trust in gems and jewelry by safeguarding the highest standards of integrity, academics, science, and professionalism.</p>
                                        <p>In 1997, the GIA moved to a new state-of-art facility in Carlsbad, California. Prior to its opening, the Mouawad brothers Fred, Alain, and Pascal made a donation in the name of their father to fund the new world headquarters, as a contribution to advance gemology research and education worldwide. As a result, the campus was named in honor of Robert Mouawad, representing a crowning moment in the family’s career. </p>
                                        <p>To this day, all Mouawad diamonds are GIA certified. By providing a professional, independent evaluation and giving added confidence to the customer, this certification (grading report) is considered a hallmark of integrity throughout the diamond world.</p>
                                    </div>
                                </div>
                                <div className="row gray-background">
                                  <div className="col-md-7 col-md-offset-1 col-xs-12">
                                    <h3 className="desktop-detail">Diamond Collection</h3>
                                    <h3 className="mobile-header">Diamond Collection</h3>
                                  </div>
                                </div>
                                <div className="row gray-background">
                                  <div className="col-xs-12">

                                          <div className="slider-box diamond-collection">
                                            <div className="single-slider-holder">
                                                <div className="single-slider">
                                                    {/* <!-- 1 --> */}
                                                    <div className="item">
                                                      <img src="images/diamond/mouawad-dynasty.jpg" alt="MOUAWAD DYNASTY"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD DYNASTY<br/>&nbsp;</h4>
                                                        <p>Weight: 51.03 carat</p>
                                                        <p>Shape: Round brilliant</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 1 end--> */}
                                                    {/* <!-- 2 --> */}
                                                    <div className="item">
                                                      <img src="images/diamond/mouawad-dragon.jpg" alt="MOUAWAD DRAGON"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD DRAGON<br/>&nbsp;</h4>
                                                        <p>Weight: 54.21 carat</p>
                                                        <p>Shape: Round brilliant</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 2 end--> */}
                                                    {/* <!-- 3 -->                                     	 */}
                                                    <div className="item">
                                                      <img src="images/diamond/mouawad-kimberley-star.jpg" alt="MOUAWAD KIMBERLEY STAR"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD KIMBERLEY STAR<br/>&nbsp;</h4>
                                                        <p>Weight: 111.11 carat</p>
                                                        <p>Shape: Modified pear brilliant-cut</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 3 end  --> */}
                                                    {/* <!-- 4 -->                                     	 */}
                                                    <div className="item">
                                                      <img src="images/diamond/mouawad-star-of-viluysk.jpg" alt="MOUAWAD STAR OF VILUYSK"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD STAR OF VILUYSK<br/>&nbsp;</h4>
                                                        <p>Weight: 80.59 carat</p>
                                                        <p>Shape: Round brilliant</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 4 end  --> */}
                                                    {/* <!-- 5 -->                                     	 */}
                                                    <div className="item">
                                                      <img src="images/diamond/jubilee.jpg" alt="JUBILÉE"/>
                                                      <div className="diamond-caption">
                                                        <h4>JUBILÉE<br/>&nbsp;</h4>
                                                        <p>Weight: 245.35 carats</p>
                                                        <p>Shape/Cut: Cushion Antique</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 5 end  --> */}
                                                    {/* <!-- 6 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/the-star-of-stanley-ho.jpg" alt="THE STAR OF STANLEY HO GRAND LISBOA, MACAU"/>
                                                      <div className="diamond-caption">
                                                        <h4>THE STAR OF STANLEY HO GRAND LISBOA, MACAU</h4>
                                                        <p>Weight: 218.08 carats</p>
                                                        <p>Shape: Cushion Modified</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 6 end  --> */}
                                                    {/* <!-- 7 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/premier-rose.jpg" alt="PREMIER ROSE"/>
                                                      <div className="diamond-caption">
                                                        <h4>PREMIER ROSE<br/>&nbsp;</h4>
                                                        <p>Weight: 137.02 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 7 end  --> */}
                                                    {/* <!-- 8 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/queen-of-holland.jpg" alt="QUEEN OF HOLLAND"/>
                                                      <div className="diamond-caption">
                                                        <h4>QUEEN OF HOLLAND<br/>&nbsp;</h4>
                                                        <p>Weight: 135.92 carats</p>
                                                        <p>Shape/Cut: Cushion</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 8 end  --> */}
                                                    {/* <!-- 9 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/mouawad-magic.jpg" alt="MOUAWAD MAGIC"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD MAGIC<br/>&nbsp;</h4>
                                                        <p>Weight: 108.81 carats</p>
                                                        <p>Shape/Cut: Emerald Cut</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 9 end  --> */}
                                                    {/* <!-- 10 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/mouawad-splendor.jpg" alt="MOUAWAD SPLENDOR"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD SPLENDOR<br/>&nbsp;</h4>
                                                        <p>Weight: 101.84 carats</p>
                                                        <p>Shape: Eleven-sided Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 10 end  --> */}
                                                    {/* <!-- 11 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/indore-pears.jpg" alt="INDORE PEARS"/>
                                                      <div className="diamond-caption">
                                                        <h4>INDORE PEARS<br/>&nbsp;</h4>
                                                        <p>Weight: 46.39 carats, 44.14 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 11 end  --> */}
                                                    {/* <!-- 12 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/ahmad-abad.jpg" alt="AHMAD ABAD"/>
                                                      <div className="diamond-caption">
                                                        <h4>AHMAD ABAD<br/>&nbsp;</h4>
                                                        <p>Weight: 78.86 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 12 end  --> */}
                                                    {/* <!-- 13 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/excelsior.jpg" alt="EXCELSIOR"/>
                                                      <div className="diamond-caption">
                                                        <h4>EXCELSIOR<br/>&nbsp;</h4>
                                                        <p>Weight: 69.68 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 13 end  --> */}
                                                    {/* <!-- 14 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/taylor-burton.jpg" alt="TAYLOR BURTON"/>
                                                      <div className="diamond-caption">
                                                        <h4>TAYLOR BURTON<br/>&nbsp;</h4>
                                                        <p>Weight: 69.42 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 14 end  --> */}
                                                    {/* <!-- 15 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/mouawad-mondera.jpg" alt="MOUAWAD MONDERA"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD MONDERA<br/>&nbsp;</h4>
                                                        <p>Weight: 60.19 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 15 end  --> */}
                                                    {/* <!-- 16 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/mouawad-blue.jpg" alt="MOUAWAD BLUE"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD BLUE<br/>&nbsp;</h4>
                                                        <p>Weight: 42.92 carats</p>
                                                        <p>Shape: Pear</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 16 end  --> */}
                                                    {/* <!-- 17 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/vargas-diamond.jpg" alt="VARGAS DIAMOND"/>
                                                      <div className="diamond-caption">
                                                        <h4>VARGAS DIAMOND<br/>&nbsp;</h4>
                                                        <p>Weight: 27.33 carats</p>
                                                        <p>Shape/Cut: Emerald Cut</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 17 end  --> */}
                                                    {/* <!-- 18 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/mouawad-lilac.jpg" alt="MOUAWAD LILAC"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD LILAC<br/>&nbsp;</h4>
                                                        <p>Weight: 24.44 carats</p>
                                                        <p>Shape/Cut: Emerald Cut</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 18 end  --> */}
                                                    {/* <!-- 19 -->                                     	 */}
                                                    <div className="item">
                                                    <img src="images/diamond/mouawad-pink.jpg" alt="MOUAWAD PINK"/>
                                                      <div className="diamond-caption">
                                                        <h4>MOUAWAD PINK<br/>&nbsp;</h4>
                                                        <p>Weight: 21.06 carats</p>
                                                        <p>Shape: Cushion Octagonal Modified</p>
                                                      </div>
                                                    </div>
                                                    {/* <!-- 19 end  --> */}
                                                </div>
                                                <div className="customNavigation ssn">
                                                    <a href="/" className="prev-slide transition"><i className="fa fa-angle-left"></i></a>
                                                    <a href="/" className="next-slide transition"><i className="fa fa-angle-right"></i></a>
                                                </div>
                                            </div>
                                            {/* <!-- single slider  end --> */}
                                        </div>
                                  </div>
                                </div>
                                <div className="row gray-background">
                                    <div className="col-md-10 col-md-offset-1 col-xs-12">
                                       <p>Diamonds and gemology have always been a passion for the Mouawads, and besides crafting fine jewelry, over the years the Mouawad family has not only acquired some of the rarest diamonds in the world, but has also been a major influence in the worldwide diamond market — often present at the most exclusive and significant auctions. Many of the historic gems that the Mouawad family has acquired now carry Mouawad as part of their designated name. The collection is arguably one of the finest in private hands with some of the largest diamonds in existence taking their place among the stunning treasures in this museum-quality collection.</p>
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </section>
                    </div>
                            


                    <Footer {...this.props} />

                </div>
            </div>
        )
    }

}

const mapStageToProps = (state) => ({
    dynamicTitleReducer: state.dynamicTitleReducer
})
    
const mapDispatchToProps = (dispatch) =>({
    setPageTitle: (title) => dispatch(setTitle(title))
})

export default connect(mapStageToProps,mapDispatchToProps)(PageHome)